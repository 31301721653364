// worker.js
let globalData = {};

self.addEventListener('message', (event) => {
  const data = event.data;

  if (data.action === 'storeGlobalData') {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    storeGlobalData(data.data);
  } else if (data.action === 'retrieveGlobalData') {
    // Enviar los datos almacenados a la pestaña solicitante
    self.postMessage({ action: 'globalDataRetrieved', globalData });
  }
});

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function storeGlobalData(data) {
  globalData = data;
}